import { useLocation, useNavigate } from "react-router-dom";

const Landing = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Define the data object
    const data = {
        date: true,
        clothes_stores: [],
        cuisines: [],
        number_of_episodes_of_aot: 0,
        alternatives_stay_home_saturday: []
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-antiquewhite p-4">
            <img className="mb-5" src="https://i.pinimg.com/originals/bb/55/97/bb5597e3fb8660a81003d1ae67d22575.gif"/>

            <h1 className="text-4xl font-semibold text-gray-800 mb-8 text-center">
                Would you like to spend 3 days and 2 nights with me at an Airbnb?
            </h1>
            <div className="space-x-4">
                {/* Yes Button */}
                <button
                    type="button"
                    className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-2xl px-16 py-4 text-center transition duration-300"
                    onClick={() => navigate('/1', { state: { data } })}
                >
                    Yes
                </button>
                {/* No Button with a friendlier alert */}
                <button
                    type="button"
                    className="text-white bg-gradient-to-br from-pink-500 to-orange-400 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-2xl px-16 py-4 text-center transition duration-300"
                    onClick={() => alert("That's okay! Maybe next time. Click 'Yes' if you change your mind!")}
                >
                    No
                </button>
            </div>
        </div>
    );
}

export default Landing;
