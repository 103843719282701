import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const Food = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const data = location?.state?.data || { cuisinies: [] }; // Initialize with an empty array if not present
    const [selectedCuisines, setSelectedCuisines] = useState(data.cuisinies || []); // State to track selected cuisines

    // Function to handle image click
    const handleCuisineClick = (cuisine) => {
        if (selectedCuisines.includes(cuisine)) {
            // Remove cuisine if already selected
            setSelectedCuisines(selectedCuisines.filter(item => item !== cuisine));
        } else if (selectedCuisines.length < 2) {
            // Add cuisine if not already selected and under limit
            setSelectedCuisines([...selectedCuisines, cuisine]);
        }
    };

    // Function to proceed with selection
    const handleSubmit = () => {
        data.cuisines = selectedCuisines;
        navigate('/3', { state: { data } });
    };

    return (
        <div
            className="flex flex-col justify-center items-center h-screen p-6">
            <img
                className="mb-5 w-full max-w-xs rounded-lg shadow-md"
                src="https://i.pinimg.com/originals/f8/96/b0/f896b04303a5fb546296d633f05d424e.gif"
                alt="Food Animation"
            />

            <h1 className="text-5xl font-bold text-gray-800 mb-8 text-center">
                What kind of food do you want to eat?
            </h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-5xl">
                <FoodCard
                    imgSrc="https://i.ytimg.com/vi/_wQ9cTUbH7U/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLC-FNwipwUtZjOSE8sUbvSOMfrhQQ"
                    altText="Korean Food"
                    title="Korean"
                    isSelected={selectedCuisines.includes("Korean")}
                    onClick={() => handleCuisineClick("Korean")}
                />
                <FoodCard
                    imgSrc="https://cdn.vox-cdn.com/thumbor/fWdJKI_dirNSL2bBWVVWVW_I4-Q=/0x0:7360x4912/1200x800/filters:focal(3092x1868:4268x3044)/cdn.vox-cdn.com/uploads/chorus_image/image/67000785/shutterstock_1435374326.0.jpg"
                    altText="Indian Food"
                    title="Indian"
                    isSelected={selectedCuisines.includes("Indian")}
                    onClick={() => handleCuisineClick("Indian")}
                />
                <FoodCard
                    imgSrc="https://food.fnr.sndimg.com/content/dam/images/food/fullset/2022/10/13/0/bowl-of-instant-ramen-with-pork-and-egg.jpg.rend.hgtvcom.1280.1280.suffix/1665672563714.jpeg"
                    altText="Japanese/Chinese Food"
                    title="Japanese / Chinese"
                    isSelected={selectedCuisines.includes("Japanese / Chinese")}
                    onClick={() => handleCuisineClick("Japanese / Chinese")}
                />
                <FoodCard
                    imgSrc="https://tb-static.uber.com/prod/image-proc/processed_images/0f52ccdf081506d52abfd837bdc5e263/719c6bd2757b08684c0faae44d43159d.jpeg"
                    altText="Fast Food"
                    title="Fast Food"
                    isSelected={selectedCuisines.includes("Fast Food")}
                    onClick={() => handleCuisineClick("Fast Food")}
                />
            </div>

            {/* Button to proceed with selection */}
            <button
                type="button"
                className="mt-5 text-white bg-gradient-to-br from-green-400 to-blue-600
                               hover:bg-gradient-to-bl focus:ring-4 focus:outline-none
                               focus:ring-green-200 dark:focus:ring-green-800
                               font-medium rounded-lg text-2xl px-16 py-4 text-center
                               transition duration-300"
                onClick={handleSubmit}
            >
                Proceed
            </button>
        </div>
    );
};

const FoodCard = ({imgSrc, altText, title, isSelected, onClick}) => {
    return (
        <div
            className={`flex flex-col items-center rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl cursor-pointer ${isSelected ? 'border-4 border-blue-400' : 'border-2 border-transparent'}`}
            onClick={onClick}
        >
            <img
                className="w-full h-48 object-cover sm:h-56 lg:h-64" // Responsive heights
                src={imgSrc}
                alt={altText}
            />
            <h2 className="mt-2 text-xl font-semibold text-gray-800">{title}</h2>
        </div>
    );
};

export default Food;
