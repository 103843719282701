import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as emailjs from "emailjs-com";
import { useRef } from "react";

const End = () => {
    const location = useLocation();
    const formRef = useRef(null); // Ref for the form

    const data = location?.state?.data;
    alert(JSON.stringify(data))

    function sendEmail(e: React.FormEvent) {
        if (formRef.current) {
            emailjs.sendForm(
                'service_k3yzlgw',
                '1O6aa_5f7Csud-XDk',
                formRef.current, // Use the form reference
                'user_k3yzlgw' // Replace with your actual user ID
            )
                .then((result) => {
                    alert("Good, see you Friday");
                    window.close();
                }, (error) => {
                    console.log(error.text);
                });
        }
    }

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-antiquewhite p-6">
            <img className="mb-5 w-64 rounded-md shadow-lg" src="https://pa1.aminoapps.com/6244/b1021db32902fce5da9607a0aeee2f0b0c021731_hq.gif" alt="Celebration" />

            <h1 className="text-4xl font-semibold text-gray-800 mb-8 text-center">
                Alright, buddy! Don't forget our condoms!
            </h1>
            <p className="text-lg text-gray-700 text-center mb-4">
                Here’s your confirmation, don’t forget to press submit:
            </p>

            <form ref={formRef} onSubmit={sendEmail} className="w-full max-w-md bg-white border border-gray-300 rounded-lg shadow-md p-4">
                <div className="flex justify-between border-b py-2 font-semibold text-gray-800">
                    <span>Item</span>
                    <span>Details</span>
                </div>

                <div className="flex justify-between py-2 border-b">
                    <span>Clothes Stores</span>
                    <span>{data.clothes_stores.length > 0 ? data.clothes_stores.join(', ') : 'No stores selected'}</span>
                    <input type="hidden" name="clothes_stores" value={data.clothes_stores.join(', ')} />
                </div>

                <div className="flex justify-between py-2 border-b">
                    <span>Cuisine</span>
                    <span>{data.cuisines[0]} and {data.cuisines[1]}</span>
                    <input type="hidden" name="cuisine" value={`${data.cuisines[0]} and ${data.cuisines[1]}`} />
                </div>

                <div className="flex justify-between py-2 border-b">
                    <span>Episodes of AOT</span>
                    <span>Minimum: {data.number_of_episodes_of_aot}</span>
                    <input type="hidden" name="episodes_of_aot" value={data.number_of_episodes_of_aot} />
                </div>

                <button
                    type="submit"
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                >
                    Submit
                </button>
            </form>
        </div>
    );
};

export default End;
