import {BrowserRouter, Route, Routes} from "react-router-dom";
import Landing from "./Pages/Landing";
import Outfit from "./Pages/Outfit";
import Food from "./Pages/Food";
import EpisodesAot from "./Pages/EpisodesAot";
import End from "./Pages/End";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Landing/>} />
                <Route path='1' element={<Outfit/>} />
                <Route path='2' element={<Food />} />
                <Route path='3' element={<EpisodesAot />} />
                <Route path='4' element={<End />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
