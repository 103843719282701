import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Outfit = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const data = location?.state?.data;

    const [isFitShopping, setIsFitShopping] = useState(false);
    const [storeNames, setStoreNames] = useState(["", "", ""]);

    const handleStoreNameChange = (index, value) => {
        const updatedStoreNames = [...storeNames];
        updatedStoreNames[index] = value;
        setStoreNames(updatedStoreNames);
    };

    const handleContinue = () => {
        data.clothes_stores = storeNames.filter(name => name.trim() !== "");
        navigate('/2', { state: { data } })
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-antiquewhite p-4">
            <img className="mb-5 w-1/2 h-auto" src="https://i.ytimg.com/vi/iCeZpELEoGE/maxresdefault.jpg"/>

            <h1 className="text-4xl font-semibold text-gray-800 mb-8 text-center">
                Do you want to go fit shopping on Friday?
            </h1>
            <div className="space-x-4">
                {/* Yes Button */}
                <button
                    type="button"
                    className="text-white bg-gradient-to-br from-green-400 to-blue-600
                               hover:bg-gradient-to-bl focus:ring-4 focus:outline-none
                               focus:ring-green-200 dark:focus:ring-green-800
                               font-medium rounded-lg text-2xl px-16 py-4 text-center
                               transition duration-300"
                    onClick={() => setIsFitShopping(true)}
                >
                    Yes
                </button>

                {/* No Button */}
                <button
                    type="button"
                    className="text-white bg-gradient-to-br from-pink-500 to-orange-400
                               hover:bg-gradient-to-bl focus:ring-4 focus:outline-none
                               focus:ring-pink-200 dark:focus:ring-pink-800
                               font-medium rounded-lg text-2xl px-16 py-4 text-center
                               transition duration-300"
                    onClick={() => navigate('/2', { state: { data } })}
                >
                    No
                </button>
            </div>

            {/* Input Fields for Store Names */}
            {isFitShopping && (
                <div className="flex flex-col mt-8 space-y-4">
                    {storeNames.map((storeName, index) => (
                        <div className="relative" key={index}>
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="white"
                                    className="bi bi-shop-window"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.37 2.37 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0M1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5m2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5"/>
                                </svg>
                            </div>
                            <input
                                type="text"
                                id={`store-name-${index}`}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm
                                           rounded-lg focus:ring-blue-500 focus:border-blue-500
                                           block w-[300px] pl-10 p-2.5 dark:bg-gray-700
                                           dark:border-gray-600 dark:placeholder-gray-400
                                           dark:text-white dark:focus:ring-blue-500
                                           dark:focus:border-blue-500"
                                placeholder={`Store name ${index + 1} (e.g., H&M, Guess, etc.)`}
                                value={storeName}
                                onChange={(e) => handleStoreNameChange(index, e.target.value)} // Update store name
                            />
                        </div>
                    ))}
                    <button
                        type="button"
                        className="text-white bg-gradient-to-br from-purple-500 to-indigo-600
                                   hover:bg-gradient-to-bl focus:ring-4 focus:outline-none
                                   focus:ring-purple-200 dark:focus:ring-purple-800
                                   font-medium rounded-lg text-2xl px-16 py-4 text-center
                                   transition duration-300"
                        onClick={handleContinue}
                    >
                        Continue
                    </button>
                </div>
            )}
        </div>
    );
};

export default Outfit;
