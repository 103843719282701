import { json, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";

const EpisodesAot = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const data = location?.state?.data;

    // State to manage the number of episodes, starting at 5
    const [episodeCount, setEpisodeCount] = useState(5);

    const handleIncrement = () => {
        if (episodeCount < 20) { // Set a maximum limit, for example, 20
            setEpisodeCount(prevCount => prevCount + 5);
        }
    };

    const handleDecrement = () => {
        if (episodeCount > 5) { // Minimum value is 5
            setEpisodeCount(prevCount => prevCount - 5);
        }
    };

    const handleNext = () => {
        data.number_of_episodes_of_aot = episodeCount;
        navigate('/4', { state: { data } });
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen bg-antiquewhite p-4">
            <img className="mb-5 w-1/2" src="https://pbs.twimg.com/media/C_LN6knXYAYMQ3M.jpg" alt="AOT" />

            <h1 className="text-4xl font-semibold text-gray-800 mb-8 text-center">
                What is the maximum number of AOT episodes you can bear?
            </h1>
            <div className="space-x-4 mb-6">
                <div className="relative flex items-center max-w-[11rem]">
                    <button type="button" id="decrement-button" onClick={handleDecrement}
                            className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-l-lg p-4 h-12 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                        <svg className="w-4 h-4 text-gray-900 dark:text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2" d="M1 1h16" />
                        </svg>
                    </button>
                    <input type="text" id="bedrooms-input" data-input-counter data-input-counter-min="5"
                           data-input-counter-max="50" aria-describedby="helper-text-explanation"
                           className="bg-gray-50 border-x-0 border-gray-300 h-12 font-medium text-center text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pb-6 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           value={episodeCount} readOnly />
                    <div
                        className="absolute bottom-1 start-1/2 -translate-x-1/2 rtl:translate-x-1/2 flex items-center text-xs text-gray-400 space-x-1 rtl:space-x-reverse">
                        <span>Episodes</span>
                    </div>
                    <button type="button" id="increment-button" onClick={handleIncrement}
                            className="bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:border-gray-600 hover:bg-gray-200 border border-gray-300 rounded-r-lg p-4 h-12 focus:ring-gray-100 dark:focus:ring-gray-700 focus:ring-2 focus:outline-none">
                        <svg className="w-4 h-4 text-gray-900 dark:text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                  strokeWidth="2" d="M9 1v16M1 9h16" />
                        </svg>
                    </button>
                </div>
            </div>

            {/* Button to proceed with selection */}
            <button
                type="button"
                className="text-white bg-gradient-to-br from-green-400 to-blue-600
                               hover:bg-gradient-to-bl focus:ring-4 focus:outline-none
                               focus:ring-green-200 dark:focus:ring-green-800
                               font-medium rounded-lg text-2xl px-16 py-4 text-center
                               transition duration-300"
                onClick={handleNext}
            >
                Proceed
            </button>
        </div>
    );
}

export default EpisodesAot;
